<ngb-carousel *ngIf="banners && banners.length > 0 && !featured">
    <ng-template ngbSlide *ngFor="let banner of banners">
        <a [href]="banner.link != null ? banner.link : ''" target="_blank">
            <img class="main-banner-image" loading="lazy" [src]="imageHelper.getImageLocation(banner.imageToken)"
                [alt]="banner.altText | titlecase">
        </a>
    </ng-template>
</ngb-carousel>

<a *ngIf="banner && featured" loading="lazy" [href]="banner.link != null ? banner.link : ''" target="_blank">
    <img class="main-banner-image" [src]="imageHelper.getImageLocation(banner.imageToken)" [alt]="banner.altText | titlecase" />
</a>