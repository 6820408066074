import { ChangeDetectionStrategy, Component, Input, OnChanges, ViewEncapsulation, SimpleChanges } from '@angular/core';
import { BrowserHelper, ImageHelper } from '../../../helpers';
import { Banner } from '../../../models';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent implements OnChanges {
    @Input('banners') banners: Banner[];
    @Input('featured') featured: boolean = true;
    banner: Banner;

    isMobile = false;

    constructor(public imageHelper: ImageHelper,
        public browserHelper: BrowserHelper) {
    }    

    ngOnChanges(changes: SimpleChanges): void {
        this.isMobile = this.browserHelper.runningOnMobile();

        if (this.banners && this.banners.length > 0) {
            if (this.featured) {                
                this.banner = this.banners.find(b => b.isFeatured && b.isMobile == this.isMobile);
            } else {
                this.banners = this.banners.filter(b => !b.isFeatured && b.isMobile == this.isMobile);
            }
        }
    }
}
