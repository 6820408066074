import { DateHelper, LocationHelper } from '../../helpers';
import { ICompanyConfig } from '../config/company-config';

export class TripSearchData {
    departureLocationName: string;
    arrivalLocationName: string;
    hasReturnTrip: boolean;
    departureDate: Date;
    returnDate: Date;
    promoCode: string;
    referral: string;
    departureDateTime: Date;
    backTripDepartureDateTime: Date;
    backTripArrivalDateTime: Date;
    idTrip: string;
    idBackTrip: string;
    seats: number;
    idPassengerType: number;
    discountType: number;
    isConfirmOpen: boolean;
    idBookingCouponToConfirmOpen: number;
    isOpen: boolean;
    isOutletSale: boolean = false;
    session:boolean = false;
    isCoronaVoucher:boolean = false;
    passengers:{idPassengerClassification:number,quantity:number}[];

    constructor(
        url: string,
        params: any,
        config: ICompanyConfig
    ) {

        url = decodeURIComponent(url);

        let segmentLengthBreak = 0;
        if (url.includes('saldao')) {
            segmentLengthBreak = 3;
            this.isOutletSale = true;
        } else {
            segmentLengthBreak = 2;
        }
        const segments = url.split('/');
        const departureLocationName = LocationHelper.restoreLocationName(segments[segmentLengthBreak], config.showStateOnLocationName);
        let arrivalLocationName = segments[segmentLengthBreak + 1];
        if (arrivalLocationName !== undefined) {
            arrivalLocationName = LocationHelper.restoreLocationName(arrivalLocationName.split('?')[0], config.showStateOnLocationName);
        } else {
            return;
        }

        this.session = params.session;
        this.isCoronaVoucher = params.isCoronaVoucher;
        this.departureLocationName = departureLocationName;
        this.arrivalLocationName = arrivalLocationName;
        this.returnDate = params.returnDate !== undefined ? DateHelper.convertToDateFromPtBrString(DateHelper.restoreDate(params.returnDate)) : undefined;
        this.departureDate = params.departureDate !== undefined ? DateHelper.convertToDateFromPtBrString(DateHelper.restoreDate(params.departureDate)) : undefined;
        this.hasReturnTrip = params.returnDate !== undefined && params.returnDate !== null;
        this.promoCode = params.cupom;
        this.referral = params.referral;
        this.seats = params.seats && params.seats != undefined && params.seats != null && params.seats != '' && params.seats > 0 ? params.seats : 1;
        this.idPassengerType = params.idPassengerType ? params.idPassengerType : config.normalPassengerType;
        this.discountType = params.discountType ? Number(params.discountType) : undefined;
        this.isConfirmOpen = params.isConfirmOpen;
        this.idBookingCouponToConfirmOpen = params.idBookingCouponToConfirmOpen;
        this.isOpen = params.isOpen !== undefined ? params.isOpen : false;
        if(params.passengers){
            var paxes = params.passengers.split(',');
            paxes.forEach(pax => {
                var paxData = pax.split(':');
                if(this.passengers == undefined){
                    this.passengers = [];
                }
                this.passengers.push({idPassengerClassification:parseInt(paxData[0]),quantity:parseInt(paxData[1])});
            });
        }else{
            this.passengers = [{idPassengerClassification:config.normalPassengerType,quantity:1}];
        }
    }

    getSearchParams = (): any => {
        let params: any;
        params = {
            departureDate: DateHelper.beautifyDateToString(this.departureDate)
        }

        if (this.hasReturnTrip) {
            params.returnDate = DateHelper.beautifyDateToString(this.returnDate);
        }

        if (this.promoCode !== undefined && this.promoCode !== '') {
            params.cupom = this.promoCode;
        }

        if (this.promoCode !== undefined && this.promoCode !== '') {
            params.referral = this.referral;
        }

        return params;
    }
}
