import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDate, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from "rxjs/operators";
import { FreeBookingEvent } from '../../../google-tag-manager';
import { BrowserHelper, DateHelper, LocationHelper, StringHelper } from '../../../helpers';
import { Dropdown, ICompanyConfig, MinimumPriceCache, ScheduleRanking, TripSearchData } from '../../../models';
import { ConfigService, GtmAnnouncerService, LocationService, PassengerClassificationService, StorageService, TripService } from '../../../services';
import { CustomDatePickerComponent } from '../custom-datepicker/custom-datepicker.component';
import { DatePrice } from '../custom-datepicker/models/date-price';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    encapsulation: ViewEncapsulation.None
})
export class SearchComponent implements OnInit, OnChanges, AfterViewInit {
    @ViewChild('departureDate', { static: false }) departureDatepicker: CustomDatePickerComponent;
    @ViewChild('arrivalDate', { static: false }) arrivalDatepicker: CustomDatePickerComponent;
    @ViewChild('departureDropDown', { static: false }) departureDropDown: NgbTypeahead;
    @ViewChild('arrivalDropDown', { static: false }) arrivalDropDown: NgbTypeahead;

    @Input('hideH1') hideH1: boolean = false;
    @Input('showDiscount') showDiscount: boolean = true;
    @Input('showPassengersDropdown') showPassengersDropdown: boolean = false;
    @Input('isBookingCouponChange') isBookingCouponChange: boolean = false;
    @Input('showGratuityPassengerDropDown') showGratuityPassengerDropDown: boolean = false;
    @Input('isOpen') isOpen: boolean = false;
    @Input('isOutlet') isOutlet: boolean = false;
    @Input('useBorderLeft') useBorderLeft: boolean = false;
    @Input('secondaryColor') secondaryColor: string = '#1E88E5';
    @Input('searchButtonText') searchButtonText: string = this.translateService.instant('search.searchButton');

    passengersQuery: string = '';
    idDepartureLocation: number;
    idArrivalLocation: number;
    clicksWithClosedPopup$ = new Observable<string>();
    inputFocus$ = new Observable<string>();
    departureClick$ = new Subject<string>();
    arrivalClick$ = new Subject<string>();
    departureFocus$ = new Subject<string>();
    arrivalFocus$ = new Subject<string>();
    departureLocations: Dropdown[] = [];
    arrivalLocations: Dropdown[] = [];
    searchFormGroup: UntypedFormGroup;
    departureLocationFormControl: UntypedFormControl;
    arrivalLocationFormControl: UntypedFormControl;
    departureDateFormControl: UntypedFormControl;
    arrivalDateFormControl: UntypedFormControl;
    arrivalLocationName: string;
    departureLocationName: string;
    fromDate: string = '';
    toDate: string = '';
    discount: string;
    minimumPriceRange: DatePrice[] = [];
    gratuityPassengersDropDownItems: Dropdown[] = [];
    tripSearchData: TripSearchData;
    readonly arrivalLocationControlName = 'arrivalLocation';
    readonly departureLocationControlName = 'departureLocation';
    runningOnMobile: boolean = false;
    departureSchedules: ScheduleRanking[] = [];
    arrivalSchedules: ScheduleRanking[] = [];
    passengersValue:string;
    public config: ICompanyConfig;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private locationService: LocationService,
        private tripService: TripService,
        private bookingProcessStorage: StorageService,
        public browserHelper: BrowserHelper,
        private translateService: TranslateService,
        private passengerClassificationService: PassengerClassificationService,
        private changeRef: ChangeDetectorRef,
        private gtmAnnouncerService: GtmAnnouncerService,
        configService: ConfigService
    ) {
        this.config = configService.company;
        this.createFormGroup();
        this.runningOnMobile = browserHelper.runningOnMobile();
    }

    departureFormatter(schedule: ScheduleRanking) { return schedule.departureLocationName; }
    arrivalFormatter(schedule: ScheduleRanking) { return schedule.arrivalLocationName; }

    searchDepartureLocations = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());

        if (this.config.idTenant != 2) {
            this.clicksWithClosedPopup$ = this.departureClick$.pipe(filter(() => {
                return !this.departureDropDown.isPopupOpen() && this.departureLocationFormControl.value == '';
            }));
            this.inputFocus$ = this.departureFocus$;
        }

        return merge(debouncedText$, this.inputFocus$, this.clicksWithClosedPopup$).pipe(
            switchMap(term => {
                return term === ''
                    ? this.locationService.getDepartureLocationsRankingByAutocompleteNoAuth('')
                    : this.locationService.getDepartureLocationsRankingByAutocompleteNoAuth(StringHelper.normalizeString(term))
            })
        );
    }

    searchArrivalLocations = (text$: Observable<string>) => {
        const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
        if (this.config.idTenant != 2) {
            this.clicksWithClosedPopup$ = this.arrivalClick$.pipe(filter(() => {
                return !this.arrivalDropDown.isPopupOpen() && this.arrivalLocationFormControl.value == '';
            }));
            this.inputFocus$ = this.arrivalFocus$;
        }

        return merge(debouncedText$, this.inputFocus$, this.clicksWithClosedPopup$).pipe(
            switchMap(term => {
                return term === ''
                    ? this.locationService.getArrivalLocationsRankingByAutocompleteNoAuth('',StringHelper.normalizeString(this.departureLocationName))
                    : this.locationService.getArrivalLocationsRankingByAutocompleteNoAuth(StringHelper.normalizeString(term),StringHelper.normalizeString(this.departureLocationName))
            })
        );
    }

    setDepartureLocationValue = (departureLocation: ScheduleRanking): void => {
        this.departureLocationFormControl.setValue(departureLocation);
        this.changeRef.detectChanges();
    }

    setArrivalLocationValue = (arrivalLocation: ScheduleRanking): void => {
        this.arrivalLocationFormControl.setValue(arrivalLocation);
        this.changeRef.detectChanges();
    }

    ngAfterViewInit(): void {

    }

    ngOnInit() {
        this.activatedRoute.queryParams.subscribe(params => {
            if ((!this.router.url.includes('onibus') || this.router.url.split('/').length !== 4) && (!this.router.url.includes('saldao') || this.router.url.split('/').length !== 5)) {
                return;
            }

            this.passengersQuery = params.passengers;

            this.tripSearchData = new TripSearchData(this.router.url, params, this.config);
            let departureLoc = {} as ScheduleRanking;
            let arrivalLoc = {} as ScheduleRanking;
            departureLoc.departureLocationName = this.tripSearchData.departureLocationName;
            arrivalLoc.arrivalLocationName = this.tripSearchData.arrivalLocationName;

            if (this.config.idTenant === 2 && this.runningOnMobile && this.departureSchedules && this.departureSchedules.length > 0) {
                const searchTerm = this.tripSearchData ? this.tripSearchData.departureLocationName : '';
                const item = this.departureSchedules.find(c => c.departureLocationName.toLowerCase() === searchTerm.toLowerCase());
                if (item) {
                    this.setDepartureLocationValue(item);
                }
            } else {
                this.departureLocationFormControl.setValue(departureLoc);
                this.arrivalLocationFormControl.setValue(arrivalLoc);
            }

            this.departureLocationName = LocationHelper.beautifyLocationName(departureLoc.departureLocationName);
            this.arrivalLocationName = LocationHelper.beautifyLocationName(arrivalLoc.arrivalLocationName);
            this.searchFormGroup.controls['discount'].setValue(this.tripSearchData.promoCode || '');
            this.fromDate = DateHelper.restoreDate(DateHelper.beautifyDateToString(this.tripSearchData.departureDate));
            this.toDate = DateHelper.restoreDate(DateHelper.beautifyDateToString(this.tripSearchData.returnDate));

            this.searchFormGroup.controls['seats'].setValue(this.tripSearchData.seats || 1);
            if (this.tripSearchData.discountType) {
                this.showGratuityPassengerDropDown = true;
            }

            this.getMinimumPricesCacheByLocatiosnName(this.tripSearchData.departureLocationName, this.tripSearchData.arrivalLocationName);
        });

        this.departureLocationFormControl.valueChanges.subscribe(event => {
            if (!event || event === '') {
                // this.arrivalLocationFormControl.disable();
                this.arrivalLocationFormControl.setValue('');
                this.changeRef.detectChanges();
                return;
            }

            if (this.tripSearchData == null || (this.tripSearchData != null && !this.tripSearchData.isCoronaVoucher)) {
                this.arrivalLocationFormControl.enable();
                this.idDepartureLocation = event.idDepartureLocation;
                this.departureLocationName = event.departureLocationName;
                if (this.config.idTenant === 2 && this.runningOnMobile) {
                    this.searchAndSetArrivalFormControl();
                }
            }
        });

        this.departureDateFormControl.valueChanges.subscribe(event => {
            if (this.config.idTenant === 2 && this.runningOnMobile) {
                this.arrivalDateFormControl.setValue(null);
            }
        });

        this.arrivalDateFormControl.valueChanges.subscribe(event => {
            if (event == '') {
                this.toDate = '';
                this.arrivalDateFormControl.setValue('');

            }
        });

        this.arrivalLocationFormControl.valueChanges.subscribe(event => {
            if (!event) {
                return;
            }

            this.idArrivalLocation = event.idArrivalLocation;
            this.arrivalLocationName = event.arrivalLocationName;

            if (!this.idArrivalLocation || !this.idDepartureLocation || this.idArrivalLocation < 1 || this.idDepartureLocation < 1) {
                return;
            }
            this.tripService.getTripMinimumPricesRange(this.idDepartureLocation, this.idArrivalLocation, new Date()).subscribe((minimumPrices: MinimumPriceCache[]) => {
                this.minimumPriceRange = this.convertMinimumPriceCacheToDatePrice(minimumPrices);
            });
        });

        if (this.showGratuityPassengerDropDown) {
            this.passengerClassificationService
                .getGratuityDiscountTypesDropDown()
                .subscribe((response) => {
                    this.gratuityPassengersDropDownItems = response;
                    if (this.tripSearchData && this.tripSearchData.discountType) {
                        this.searchFormGroup.controls['gratuityPassengersDropDown'].setValue(this.tripSearchData.discountType);
                    }
                });
        }

        if (this.config.idTenant === 2 && this.runningOnMobile) {
            this.locationService.getDepartureLocationsRankingByAutocompleteNoAuth('', this.runningOnMobile)
                .subscribe((response: ScheduleRanking[]) => {
                    this.departureSchedules = response;
                    const searchTerm = this.tripSearchData ? this.tripSearchData.departureLocationName : '';
                    const item = response.find(c => c.departureLocationName.toLowerCase() === searchTerm.toLowerCase());
                    if (item) {
                        this.setDepartureLocationValue(item);
                        this.searchAndSetArrivalFormControl();
                    } else {
                        this.departureLocationFormControl.setValue('');
                        this.changeRef.detectChanges();
                    }
                });
        }

        if (this.config.idTenant === 2 && this.runningOnMobile && this.tripSearchData) {
            this.departureDateFormControl.setValue(DateHelper.convertToFormattedDate(this.tripSearchData.departureDate, 'DD-MM-YYYY', 'YYYY-MM-DD'));
            this.arrivalDateFormControl.setValue(DateHelper.convertToFormattedDate(this.tripSearchData.returnDate, 'DD-MM-YYYY', 'YYYY-MM-DD'));
        }
    }

    searchAndSetArrivalFormControl = (): void => {
        this.locationService.getArrivalLocationsRankingByAutocompleteNoAuth('', this.departureLocationFormControl.value.DepartureLocationName, this.runningOnMobile)
            .subscribe((response: ScheduleRanking[]) => {
                this.arrivalSchedules = response;
                const searchTerm = this.tripSearchData ? this.tripSearchData.arrivalLocationName : '';
                const item = response.find(c => c.arrivalLocationName.toLowerCase() === searchTerm.toLowerCase());
                if (item) {
                    this.setArrivalLocationValue(item);
                } else {
                    this.arrivalLocationFormControl.setValue('');
                }
            });
    }

    getMinimumPricesCacheByLocatiosnName = (departureLocationName: string, arrivalLocationName: string): void => {
        this.tripService.getCachedMinimumPricesByLocationsName(departureLocationName, arrivalLocationName, new Date())
            .subscribe((minimumPrices: MinimumPriceCache[]) => {
                this.minimumPriceRange = this.convertMinimumPriceCacheToDatePrice(minimumPrices);
            }, error => this.minimumPriceRange = []);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['isBookingCouponChange']) {
            if (this.isBookingCouponChange) {
                this.departureLocationFormControl.disable();
                this.arrivalLocationFormControl.disable();
                this.departureDateFormControl.disable();
                this.arrivalDateFormControl.disable();
            } else {
                this.departureLocationFormControl.enable();
                this.arrivalLocationFormControl.enable();
                this.departureDateFormControl.enable();
                this.arrivalDateFormControl.enable();
            }
        }
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.config.idTenant === 2 && this.runningOnMobile) {
            return;
        }

        let dateFound = false;
        let isDeparture = false;
        let isArrival = false;
        let path = event.path || (event.composedPath && event.composedPath());
        for (let p of path) {
            if (p.className === undefined) {
                continue;
            }

            if (p.classList.toString().includes('date')) {
                dateFound = true;
            }

            if (p.classList.toString().includes('departure')) {
                isDeparture = true;
            }

            if (p.classList.toString().includes('arrival')) {
                isArrival = true;
            }
        }

        if (!dateFound) {
            if (this.departureDatepicker?.display) {
                this.departureDatepicker.toggle();
            }

            if (this.arrivalDatepicker?.display) {
                this.arrivalDatepicker.toggle();
            }
        } else {
            if (isDeparture) {
                if (this.arrivalDatepicker?.display) {
                    this.arrivalDatepicker.toggle();
                }
            }

            if (isArrival) {
                if (this.departureDatepicker?.display) {
                    this.departureDatepicker.toggle();
                }
            }
        }
    }

    createFormGroup = (): void => {
        this.departureLocationFormControl = new UntypedFormControl('', Validators.required);
        this.arrivalLocationFormControl = new UntypedFormControl('', Validators.required);
        this.departureDateFormControl = new UntypedFormControl('', Validators.required);
        this.arrivalDateFormControl = new UntypedFormControl(null);
        // this.arrivalLocationFormControl.disable();

        this.searchFormGroup = this.formBuilder.group({
            departureLocation: ['', Validators.required],
            arrivalLocation: ['', Validators.required],
            departureDate: this.departureDateFormControl,
            arrivalDate: this.arrivalDateFormControl,
            discount: [''],
            seats: ['', Validators.required],
            gratuityPassengersDropDown: ['']
        });
    }

    getTrips = (): void => {
        if (this.departureLocationName === undefined
            || this.departureLocationName === ''
            || this.arrivalLocationName === undefined
            || this.arrivalLocationName === ''
            || (this.showGratuityPassengerDropDown && this.searchFormGroup.controls['gratuityPassengersDropDown'].value === "")) {
            this.departureLocationFormControl.markAsTouched();
            this.arrivalLocationFormControl.markAsTouched();
            this.searchFormGroup.controls['gratuityPassengersDropDown'].markAsTouched();
            return;
        }

        if (this.isBookingCouponChange) {
            this.bookingProcessStorage.clear(['tempSummaryKey', 'bookingSummary', 'passengerTypes']);
        } else {
            this.bookingProcessStorage.clear(['passengerTypes']);
        }
        const prettyDepLocationName = LocationHelper.beautifyLocationName(this.departureLocationName);
        const prettyArrivocationName = LocationHelper.beautifyLocationName(this.arrivalLocationName);
        const discount = this.searchFormGroup.controls['discount'].value;
        const seats = this.searchFormGroup.controls['seats'].value;
        const discountType = this.searchFormGroup.controls['gratuityPassengersDropDown'].value;

        let params: any;
        params = {};

        if (this.tripSearchData && this.tripSearchData.isConfirmOpen) {
            params.isConfirmOpen = true;
        }

        if (this.tripSearchData) {
            params.session = this.tripSearchData.session;
            params.isCoronaVoucher = this.tripSearchData.isCoronaVoucher;

            if (this.tripSearchData.idBookingCouponToConfirmOpen) {
                params.idBookingCouponToConfirmOpen = this.tripSearchData.idBookingCouponToConfirmOpen;
            }
            if (this.tripSearchData.idPassengerType) {
                params.idPassengerType = this.tripSearchData.idPassengerType;
            }
        }

        if (this.fromDate !== '' && (this.departureDateFormControl.value === '' || this.departureDateFormControl.value === null)) {
            params.departureDate = DateHelper.beautifyDate(this.fromDate);
        } else if (this.departureDateFormControl.value && this.departureDateFormControl.value !== '') {
            params.departureDate = DateHelper.convertToFormattedDate(this.departureDateFormControl.value, 'YYYY-MM-DD', 'DD-MM-YYYY');
        }

        if (this.toDate !== '' && (this.arrivalDateFormControl.value === '' || this.arrivalDateFormControl.value === null)) {
            params.returnDate = DateHelper.beautifyDate(this.toDate);
        } else if (this.arrivalDateFormControl.value && this.arrivalDateFormControl.value !== '') {
            params.returnDate = DateHelper.convertToFormattedDate(this.arrivalDateFormControl.value, 'YYYY-MM-DD', 'DD-MM-YYYY');
        }

        if (discount) {
            params.cupom = discount;
        }

        if (seats) {
            params.seats = seats;
        }

        if (discountType) {
            params.discountType = discountType;
            let discountTypeDescription = this.gratuityPassengersDropDownItems.find(value => value.key == discountType).value;
            let freeBookingEvent = new FreeBookingEvent(discountTypeDescription);
            this.gtmAnnouncerService.announceEvent(freeBookingEvent);
        }

        if (this.isOpen || this.tripSearchData?.isOpen && params.departureDate == null) {
            params.isOpen = true;
            params.idPassengerType = this.config.offerOpen || this.tripSearchData?.isOpen ? this.config.openIdPassengerType : this.config.normalPassengerType;
        }

        if (this.isOutlet) {
            this.router
                .navigate([`/onibus/saldao`, prettyDepLocationName, prettyArrivocationName], {
                    queryParams: params
                });
            return;
        }

        if(this.passengersValue){
            params.passengers = this.passengersValue;
        }	

        this.router
            .navigate([`/onibus`, prettyDepLocationName, prettyArrivocationName], {
                queryParams: params
            });
    }

    onDepartureDateSelected = (event: string): void => {
        this.fromDate = event;
        this.toDate = '';
    }

    onArrivalDateSelected = (event: string): void => {
        this.toDate = event;
    }

    toggleDepartureDatepicker = (): void => {
        this.departureDatepicker.toggle();
    }

    onBlurEvent(event: any) {
        if (event.target.value == '') {
            this.toDate = '';
        }
    }

    toggleArrivalDatepicker = (): void => {
        this.arrivalDatepicker.toggle();
    }

    canSearch = (): boolean => {
        let result: boolean = this.departureLocationName !== undefined
            && this.arrivalLocationName !== ''
        return result;
    }

    getMinDate = (): NgbDate | undefined => {
        let date = this.fromDate.split('/');
        if (this.fromDate === '') {
            return undefined;
        }

        let result = new NgbDate(Number.parseInt(date[2]), Number.parseInt(date[1]), Number.parseInt(date[0]));

        return result;
    }

    convertMinimumPriceCacheToDatePrice = (minimumPriceCaches: MinimumPriceCache[]): DatePrice[] => {
        const result: DatePrice[] = [];
        for (let minimumPriceCache of minimumPriceCaches) {
            let date = new NgbDate(minimumPriceCache.tripDate.year, minimumPriceCache.tripDate.month, minimumPriceCache.tripDate.day);
            result.push(new DatePrice(date, minimumPriceCache.minimumPrice));
        }
        return result;
    }

    invertLocations = (): void => {
        const departureValue = { 
            departureLocationName: this.arrivalLocationFormControl.value.arrivalLocationName,
            idDepartureLocation: this.arrivalLocationFormControl.value.idArrivalLocation,
            quantity: this.arrivalLocationFormControl.value.quantity,
        } as any as ScheduleRanking;
        const arrivalLocationValue = { 
            arrivalLocationName: this.departureLocationFormControl.value.departureLocationName,
            idArrivalLocation: this.departureLocationFormControl.value.idDepartureLocation,
            quantity: this.departureLocationFormControl.value.quantity,
        } as any as ScheduleRanking;

        this.departureLocationFormControl.setValue(departureValue);
        this.arrivalLocationFormControl.setValue(arrivalLocationValue);
        
        if (this.router.url.includes('onibus')) {
            this.getTrips();
        }
    }
}
